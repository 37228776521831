import styled from 'styled-components';

export const InRecipeProductsFoodservicesComponent = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 40, left: 0, right: 0, bottom: 40 })};

  margin: 80px auto;  

  > h2 {
    margin: 0 ${({ theme }) => theme.spacing(64)};
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.brown};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(32)};
    }
  }
    
  .loading-spinner {
    max-height: 200px;
  }
  .button-container{
    display: flex;
    justify-content: center;
  }
`;

export const ProductList = styled.ul`
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  grid-template-columns: auto;
  grid-gap: 80px; /* Adjust the gap between grid items as needed */
  justify-items: center;
  align-items: start;
  margin-bottom: 0;  

  li {
    display: flex;
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing({ top: 0, left: 16, right: 16, bottom: 16 })};
    overflow: hidden;
    max-width: 380px;

    ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
      margin: ${({ theme }) => theme.spacing({ top: 0, left: 32, right: 32, bottom: 0 })};
    }
    .image-wrapper{
      width: 380px;
      height: 380px;
      display: flex;
      margin: auto;
      justify-content: center;
      background-color: ${({ theme }) => theme.colours.yellow};
      ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
        width: 280px;
        height: 280px;
      }
    }
    img {
      display: block;
      max-width: 75%;
      margin: 0 auto ${({ theme }) => theme.spacing(12)};
      object-fit: contain;
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: 75%;
      }
      transition: all 0.4s ease-in-out;
    }

    a {
      position: relative;
      text-decoration: none;

      .recipe-card-badge {
        position: absolute;
        width: 50px;
        top: -4px;
        left: 0;

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          top: -5px;
          left: 0px;
          width: 80px;
        }
      }
      &:hover{
        .image-wrapper{
          img{
            scale: 1.1;
          }
        }
      }
    }
    .desc{
      font-size: 16px;
      color: ${({ theme }) => theme.colours.brown};
      display: block;
      text-align: center;
      line-height: 1.3;
    }
    h4 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colours.blue};
      text-transform: capitalize;
      margin-top: 20px;
    }
    .h4 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colours.blue};
      text-transform: capitalize;
      margin-top: 20px;
    }
  }
`;

export const ProductCardComponentInRec = styled.article`
	position: relative;
    width: 468px;
	max-width: 90%;
	overflow: hidden;
    margin: auto;
	footer{
		margin: 15px auto;
	}
	h3{
		margin-bottom: 0;
		color: ${({ theme }) => theme.colours.blue};
		text-transform: capitalize;
		margin-top: 20px;
		display: block;
		text-align: center;
	}
	p{
		font-size: 16px;
		color: ${({ theme }) => theme.colours.brown};
		display: block;
		text-align: center;
		line-height: 1.3;
	}
	.desc{
		font-size: 16px;
		color: ${({ theme }) => theme.colours.brown};
		display: block;
		text-align: center;
		line-height: 1.3;
	}
	a{
		display: flex;
		flex-direction: column;
		text-decoration: none;
		.desc{
			font-size: 16px;
			color: ${({ theme }) => theme.colours.brown};
			display: block;
			text-align: center;
			line-height: 1.3;
			text-decoration: none;
            margin: 15px auto;
		}
		h3{
			margin-bottom: 0;
			color: ${({ theme }) => theme.colours.blue};
			text-transform: capitalize;
			margin-top: 20px;
			display: block;
			text-align: center;
			text-decoration: none;
		}

	}
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.yellow};
    max-height: 300px;
	min-height: 0;  
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 80%;
    }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 320px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 360px;
    }
    
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
      object-fit: contain;
      transform: translate(-50%, -50%);
      transition: 0.4s;
      background-color: ${({ theme }) => theme.colours.yellow};
      ${({ type }) => (type === 'product' ? `max-width: 45%;` : '')}

      &:hover {
        transform: translate(-50%, -50%) scale(1.02);
      }
    }
      
  }
`;