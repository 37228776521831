export const filterConfig = ({ type }) => {
  const configs = {
    recipe: {
      facetsKey: 'recipeFacets',
      filtersKey: 'recipeFilters',
      options: [
        {
          key: 'productType',
          facetKey: 'productTypeFacets',
          filterKey: 'productTypeFilters',
          labelKey: 'BushsBeans.Search.Filters.Recipes.ProductType',
          label: 'Product Type',
        },
        {
          key: 'keyIngredient',
          facetKey: 'keyIngredientFacets',
          filterKey: 'keyIngredientFilters',
          labelKey: 'BushsBeans.Search.Filters.Recipes.KeyIngredient',
          label: 'Key Ingredient',
        },
        {
          key: 'beanVariety',
          facetKey: 'beanVarietyFacets',
          filterKey: 'beanVarietyFilters',
          labelKey: 'BushsBeans.Search.Filters.Recipes.BeanVariety',
          label: 'Bean Variety',
        },
        {
          key: 'typeOfDish',
          facetKey: 'typeOfDishFacets',
          filterKey: 'typeOfDishFilters',
          labelKey: 'BushsBeans.Search.Filters.Recipes.TypeOfDish',
          label: 'Type Of Dish',
        },
      ],
      sortOptions: [
        {
          key: 'dateDesc',
          labelKey: 'BushsBeans.Search.Sorting.Newest',
          label: 'Newest',
        },
        {
          key: 'dateAsc',
          labelKey: 'BushsBeans.Search.Sorting.Oldest',
          label: 'Oldest',
        },
      ],
    },
    product: {
      facetsKey: 'productFacets',
      filtersKey: 'productFilters',
      options: [
        {
          key: 'productType',
          facetKey: 'productTypeFacets',
          filterKey: 'productTypeFilters',
          labelKey: 'BushsBeans.Search.Filters.Products.productType',
          label: 'Product Type',
        },
        {
          key: 'productSegments',
          facetKey: 'productSegmentFacets',
          filterKey: 'productSegmentFilters',
          labelKey: 'BushsBeans.Search.Filters.Products.productSegments',
          label: 'Product Segment',
        },
      ],
      sortOptions: [
        {
          key: 'dateDesc',
          labelKey: 'BushsBeans.Search.Sorting.Newest',
          label: 'Newest',
        },
        {
          key: 'dateAsc',
          labelKey: 'BushsBeans.Search.Sorting.Oldest',
          label: 'Oldest',
        },
      ],
    },
    article: {
      facetsKey: 'articleFacets',
      filtersKey: 'articleFilters',
      options: [
        {
          key: 'topic',
          facetKey: 'topicFacets',
          filterKey: 'topicFilters',
          labelKey: 'BushsBeans.Search.Filters.Article.topic',
          label: 'Topic',
        },
      ],
      sortOptions: [
        {
          key: 'dateDesc',
          labelKey: 'BushsBeans.Search.Sorting.Newest',
          label: 'Newest',
        },
        {
          key: 'dateAsc',
          labelKey: 'BushsBeans.Search.Sorting.Oldest',
          label: 'Oldest',
        },
      ],
    },
  };

  if (type in configs) return configs[type];

  return {};
};
