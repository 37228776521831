import React from 'react';
import {withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    CTAContainer,
    ContentWrapper,
    HeadingWrapper,
    CopyWrapper,
    ButtonsWrapper,
    ImageContainerWrapper,
    CTAWrapper
} from './CallToAction.styles.js';
import Button from 'components/shared/Button';
import { useDeviceResize } from 'hooks/index.js';
import PropTypes from "prop-types";
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

const CallToAction = (props) => {
    const experienceEditor = isExperienceEditorActive();
    const {fields = {}, params = {}, sitecoreContext} = props;
    const {title = '', copy = '', image = null, cta = {}, ctaLocation = {}} = fields;
    const {style = '', topPadding = false, bottomPadding = false} = params;
    const device = useDeviceResize();
    const {site} = sitecoreContext;
    const ctaWrapperClasses = [
        site.name,
        topPadding ? 'include-top-padding' : '',
        bottomPadding ? 'include-bottom-padding' : '',
    ].filter(Boolean).join(' ');

    return (
        <CTAWrapper site={site.name} className={ctaWrapperClasses} id="btm-cta">
            <CTAContainer>
                {image && image.value && image.value.src && (
                    <ImageContainerWrapper>
                        <img
                            src={image.value.src}
                            alt={image.value.alt || 'Product Image'}
                        />
                    </ImageContainerWrapper>
                )}
                <ContentWrapper site={site.name}>
                    {title && <HeadingWrapper>{typeof title === 'string' ? title : title.value}</HeadingWrapper>}
                    {copy && copy.value && copy.value.length > 5 && (
                        <CopyWrapper>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: typeof copy === 'string' ? copy : copy.value,
                                }}
                            />
                        </CopyWrapper>
                    )}
                    <ButtonsWrapper site={site.name}>
                        {cta && cta.value.text && cta.value.href && (
                            <Button
                                text={cta.value.text}
                                href={cta.value.href}
                                variant="brown"
                                size={device === 'desktop' ? 'large' : 'small'}
                            />
                        )}
                        {ctaLocation && ctaLocation.value.text && ctaLocation.value.href && (
                            <Button
                                text={ctaLocation.value.text}
                                href={ctaLocation.value.href}
                                variant="brown"
                                size={device === 'desktop' ? 'large' : 'small'}
                            />
                        )}
                    </ButtonsWrapper>
                </ContentWrapper>
            </CTAContainer>
        </CTAWrapper>
    );
};

CallToAction.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        copy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        image: PropTypes.object,
        cta: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            onClick: PropTypes.string,
        }),
        ctaLocation: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            onClick: PropTypes.string,
        }),
    }),
    params: PropTypes.shape({
        style: PropTypes.oneOf(['red', 'tan', 'yellow', 'blue','brown', 'transparent']),
    }),
};

export default withSitecoreContext()(CallToAction);