import styled from 'styled-components';

const getVariantColours = ({ type, theme, invertColor = false } = {}) => {
  const variants = {
      red: {
          background: invertColor ? theme.colours.red : theme.colours.yellow,
          text: invertColor ? theme.colours.yellow : theme.colours.red,
          hover: {
              background: invertColor ? theme.colours.yellow : theme.colours.red,
              text: invertColor ? theme.colours.red : theme.colours.yellow,
          },
      },
      yellow: {
          background: invertColor ? theme.colours.yellow : theme.colours.red,
          text: invertColor ? theme.colours.red : theme.colours.yellow,
          hover: {
              background: invertColor ? theme.colours.red : theme.colours.yellow,
              text: invertColor ? theme.colours.yellow : theme.colours.red,
          },
      },
      blue: {
          background: invertColor ? theme.colours.blue : theme.colours.yellow,
          text: invertColor ? theme.colours.yellow : theme.colours.blue,
          hover: {
              background: invertColor ? theme.colours.yellow : theme.colours.blue,
              text: invertColor ? theme.colours.blue : theme.colours.yellow,
          },
      },
      brown: {
          background: invertColor ? theme.colours.brown : theme.colours.yellow,
          text: invertColor ? theme.colours.yellow : theme.colours.brown,
          hover: {
              background: invertColor ? theme.colours.yellow : theme.colours.brown,
              text: invertColor ? theme.colours.brown : theme.colours.yellow,
          },
      },
  };

  const types = {
    product: variants.yellow,
    recipe: variants.red,
  };

  if (type in types) return types[type];

  return types.product;
};

export const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;

  &:hover {
    opacity: 1;
  }

  .ribbon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
    pointer-events: none;
  }
  .product & .ribbon {
    right: 0;
    margin: auto;
    width: 250px;
    padding: 15px 0;
    border-radius: 50px;
    background-color: transparent;
    top: 80%;
    span{
      font-size: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colours.red}
    }
  }
`;

export const ProductCardComponent = styled.article`
    position: relative;
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    overflow: visible;
    height: fit-content;
    padding: 40px 0;
	@media (max-width: 1100px){
		padding: 10px 0;
	}
    .image-container{
      height: 100%;
    }

  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: visible;
    background-color: transparent;
    transition: all 0.4s ease-in-out;
    > a {
      position: relative;
      display: flex;
      flex-direction: column;
      &:hover {
        p {
          color: ${({ theme }) => theme.colours.adaBrown};
        }
      }
    }
      
    .product-inner-image-container {
		position: relative;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		object-fit: contain;
		transition: 0.4s;
		max-width: 80%;
		margin: auto;
		@media (max-width: 500px){
			max-width: 90%;
		}
		@media (max-width: 550px){
			max-width: 80%;
		}
        @media (max-width: 800px){
            max-width: 70%;
        }
 
      img {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        max-width: 100%;  
        transition: all 0.4s ease-in-out;  
		  .card-link {
			  box-sizing: border-box;
			  width: 100%;
			  padding: ${({ theme }) => theme.spacing(8)};
			  color: ${({ theme, type }) => getVariantColours({ theme, type }).text};
			  font-size: ${({ theme }) => theme.spacing(14)};
			  text-align: center;
			  text-transform: uppercase;
			  text-decoration: none;
			  background-color: ${({ theme, type }) => getVariantColours({ theme, type }).background};
		  }  
      }
      .product-card-badge {
        width: 80px;
        position: absolute;
        top: -10px;
        left: 0;
        ${({ theme }) => theme.breakPoints.minMaxBp('481px', '768px')} {
          top: -20px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.minMaxBp('769px', '1200px')} {
          top: -10px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
          top: -5px;
          width: 35px;
        }
      }
    }
  } 
  &:hover {
	 .image-container{
		.product-inner-image-container{
			img{
				transform: scale(1.05);
			}
		}
	 }
  }
`;

export const ProductCardFooter = styled.footer`
	height: fit-content;
	padding: ${({ theme }) => theme.spacing({ top: 25, left: 0, right: 0, bottom: 0 })};
	${({ theme }) => theme.breakPoints.minBp('desktop')} {
		padding: ${({ theme }) => theme.spacing({ top: 43, left: 40, right: 40, bottom: 0 })};
	}
	h2{
		font-size: 31px;
		margin-bottom: 5px;
		line-height: 1.2;
		color: ${({ theme }) => theme.colours.brown};
	}
	h3{
		font-size: 31px;
		line-height: 1.2;
		font-weight: 700;
		color: ${({ theme }) => theme.colours.brown};
        margin:  0 auto 5px auto;
        max-width: 80%;
		${({ theme }) => theme.breakPoints.maxBp('tablet')}{
           max-width: 100%;
        }
	}
	p{
		margin: 0;
		color: ${({ theme }) => theme.colours.adaBrown};
		font-size: 18px;
		line-height: 1.5;
		text-align: center;
		${({ theme }) => theme.breakPoints.maxBp('tablet')} {
			font-size: ${({ theme }) => theme.spacing(12)};
			line-height: ${({ theme }) => theme.spacing(16)};
		}
	}
    
	@media (max-width: 800px) {
		h2{
			font-size: 18px;
		}
		h3{
			font-size: 18px;
		}
		p{
			font-size: 16px;
		}
	}
`;

export const CardComponent = styled.article`
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  overflow: hidden;
  &.product {
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(0%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 100%;
      .img{
        position: relative;
        top: 0px;
        left: 0px;
        transform: none;
      }
      @media (max-width: 1199px){
        .product-inner-image-container{
          img {
            position: relative;
            top: 0px;
            left: 0px;
            transform: none;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    footer{
      &.productCardFooter{
        h2{
			font-size: 31px;
			margin-bottom: 5px;
			line-height: 1.2;
        }
		h3{
			font-size: 31px;
			margin-bottom: 5px;
			line-height: 1.2;
		}  
        p{
			font-size: 18px;
			line-height: 1.5;
        }
        @media (max-width: 800px) {
          h2{
            font-size: 18px;
          }  
          h3{
			font-size: 18px;
		  }  
          p{
            font-size: 16px;
          }
        }
      }
    }
    .image-container{
      height: 100%;
    }
  }
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: transparent;
    max-height: 180px;
    transition: all 0.4s ease-in-out;
    &:hover{
      background-color: ${({ theme }) => theme.colours.blue};
    }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 360px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 560px;
    }

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((4 / 3) * 100%);
    }

    &.article {
      max-height: 240px;
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-height: 320px;
      }
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-height: 480px;
      }
    }
    > a {
      position: relative;
      display: flex;
      flex-direction: column;
      &:hover {
        p {
          color: ${({ theme }) => theme.colours.adaBrown};
        }
      }
    }

    &:hover {
      > img {
        transform: translate(-50%, -50%) scale(1.02);
      }
      &${HoverWrapper} {
        visibility: visible;
        opacity: 1;
      }
      .image-container{
        background-color: ${({ theme }) => theme.colours.red};
      }
    }
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(-50%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 70%;
      img {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }
      .product-card-badge {
        width: 80px;
        position: absolute;
        top: -10px;
        left: 0;
        ${({ theme }) => theme.breakPoints.minMaxBp('481px', '768px')} {
          top: -20px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.minMaxBp('769px', '1200px')} {
          top: -10px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
          top: -5px;
          width: 35px;
        }
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    .card-link {
      box-sizing: border-box;
      width: 100%;
      padding: ${({ theme }) => theme.spacing(8)};
      color: ${({ theme, type }) => getVariantColours({ theme, type }).text};
      font-size: ${({ theme }) => theme.spacing(14)};
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      background-color: ${({ theme, type }) => getVariantColours({ theme, type }).background};
    }
  }

  .button-love {
    position: absolute;
    top: 0;
    right: 0;
    margin: ${({ theme }) => theme.spacing(8)};
    z-index: 10;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => theme.spacing(16)};
    }
  }

  footer {
    height: 100%;
    padding: ${({ theme }) => theme.spacing({ top: 16, left: 0, right: 0, bottom: 0 })};
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing({ top: 40, left: 40, right: 40, bottom: 0 })};
    }
  }

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-transform: uppercase;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }
  }
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-transform: uppercase;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }
  }  
  .c-card-h{
    color:  ${({ theme }) => theme.colours.red};
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colours.adaBrown};
    text-align: center;
    ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
      font-size: ${({ theme }) => theme.spacing(12)};
      line-height: ${({ theme }) => theme.spacing(16)};
    }
  }
`;

export const CardPromoComponent = styled.article`
  position: relative;
  width: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
	&.product {
    .image-container {
      height: 100%;
      &:hover {
        background-color: ${({theme}) => theme.colours.blue};
      }
    }
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(0%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 100%;
      .img {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        right: 0;
        display: block;
        margin: auto;
      }
      @media (max-width: 1199px) {
          img {
            position: relative;
            top: 0px;
            left: 0px;
            transform: none;
            right: 0;
            margin: auto;
            display: block;
          }
      }
    }
    footer {
      &.productCardPromoFooter {
        text-align: center;
        h2 {
			font-size: 31px;
			margin-bottom: 5px;
			line-height: 1.2;
			color: ${({theme}) => theme.colours.yellow};
			text-align: center;
			display: block;
			width: 100%;
        }
		h3 {
			font-size: 31px;
			margin-bottom: 5px;
			line-height: 1.2;
			color: ${({theme}) => theme.colours.yellow};
			text-align: center;
			display: block;
			width: 100%;
		}
        p {
			font-size: 22px;
			color: ${({theme}) => theme.colours.yellow};
			line-height: 1.5;
			text-align: center;
			display: block;
			width: 100%;
			text-shadow: 0 0 5px #a31d19;
        }
        @media (max-width: 800px) {
          h2 {
            font-size: 22px;
          }
		  h3 {
			font-size: 22px;
		  }
          p {
            font-size: 20px;
          }
        }
      }
      .image-container {
        height: 100%;
      }
    }
  }
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.yellow};
    max-height: 180px;
    transition: all 0.4s ease-in-out;
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((4 / 6) * 100%);
    }

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 340px;
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      max-height: 560px;
    }

    &.article {
      max-height: 240px;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-height: 360px;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-height: 480px;
      }
    }

    > a {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &:hover {
      > img {
        transform: translate(-50%, -50%) scale(1.02);
      }
      &.hover-image {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .product-inner-image-container {
    position: absolute;
    top: 50%;
    left: 74%;
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease 0s;
    max-width: 70%;
    img {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      margin: auto;
      display: block;
    }
    .product-card-badge {
      position: absolute;
      left: 0px;
      width: 80px;

      ${({ theme }) => theme.breakPoints.minMaxBp('481px', '768px')} {
        top: -20px;
        width: 80px;
        left: -15px;
      }
      ${({ theme }) => theme.breakPoints.minMaxBp('769px', '1200px')} {
        top: -10px;
        width: 80px;
        left: -15px;
      }
      ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
        top: -5px;
        width: 35px;
        left: -10px;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: 0.4s;

    ${({ type }) => (type === 'product' ? `max-width: 35%;` : '')}

    &:hover {
      // transform: translate(-50%, -50%) scale(1.02);
    }
  }

  .button-love {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => theme.spacing(16)};
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    padding: ${({ theme }) => theme.spacing(20)};
    background-color: ${({ theme }) => theme.colours.red};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(40)} ${({ theme }) => theme.spacing(50)};
    }
  }

  h2 {
    margin: 0;
    //color: ${({ theme }) => theme.colours.yellow};
    color: #fff;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          max-width: 100%;
        }
    }
    h3 {
		margin: 0;
		//color: ${({ theme }) => theme.colours.yellow};
		color: #fff;
		font-weight: 400;
		text-align: left;
		text-transform: uppercase;
		background-color: ${({ theme }) => theme.colours.red};
		font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
		line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          max-width: 100%;
        }
    }  
    .c-card-h{
		color:  ${({ theme }) => theme.colours.red};
		font-weight: 400;
		text-align: left;
		text-transform: uppercase;
		font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
		line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
    }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colours.white};
    text-align: left;
    &.time {
      margin-left: auto;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-top: ${({ theme }) => theme.spacing(18)};
        margin-left: 0;
      }
    }
    &.date {
      margin-top: 16px;
    }
  }
`;


export const RecipeCardComponent = styled.article`
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colours.blue};
`;

export const RecipeCardPromoComponent = styled.article`
    position: relative;
    background-color: ${({ theme }) => theme.colours.blue};
    overflow: hidden;
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
	width: 100%;
	max-width: 100%;
    @media (max-width: 1200px){
        max-width: 90%;
    }
	&.item-left{
	    justify-self: flex-start;
    }
    &.item-right{
	    justify-self: flex-end;
    }
    
`;

export const RecipePosterImg = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	background-color: rgb(255, 202, 46);
	max-height: 180px;
	transition: all 0.4s ease-in-out 0s;
	padding-top: 72.65%;
	> a {
		position: relative;
		display: flex;
		flex-direction: column;
	}
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: translate(-50%, -50%);
		transition: all 0.4s ease 0s;
	}
	&:hover {
		> img {
			transform: translate(-50%, -50%) scale(1.02);
		}

		&.hover-image {
			visibility: visible;
			opacity: 1;
		}
	}

`;
export const RecipePromoPosterImg = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colours.yellow};
	transition: all 0.4s ease-in-out;
	padding-top: 37.44%;

	> a {
		position: relative;
		display: flex;
		flex-direction: column;
	}
    img{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform: translate(-50%, -50%);
		transition: all 0.4s ease 0s;
    }
	&:hover {
		> img {
			transform: translate(-50%, -50%) scale(1.02);
		}
		&.hover-image {
			visibility: visible;
			opacity: 1;
		}
	}
    @media (max-width: 1199px){
		padding-top: 72.65%;
    }
`;

export const RecipeFooter = styled.footer`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
        position: relative;
        padding: ${({ theme }) => theme.spacing(20)} !important;
        background-color: ${({ theme }) => theme.colours.blue};
        color: ${({ theme }) => theme.colours.yellow};
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            padding: ${({ theme }) => theme.spacing(32)} ${({ theme }) => theme.spacing(32)} !important;
        }
        h3{
            color: ${({ theme }) => theme.colours.yellow};
            font-size: 16px;
            margin: 0;
            font-weight: 700;
            line-height: 1.2;
            text-align: left;
        }
        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
            h3{
				font-size: 32px;
            }
        }
`;

export const ViewButtonWrapper = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 8, bottom: 8, left: 0, right: 0 })};
  color: ${({ theme }) => theme.colours.blue};
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colours.yellow};
  font-size: 24px;
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => theme.spacing({ top: 14, bottom: 14, left: 0, right: 0 })};
  }
`;

export const MobileButtonWrapper = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 10, bottom: 8, left: 0, right: 0 })};
  color: ${({ theme, type }) => getVariantColours({ theme, type }).text};
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ theme, type }) => getVariantColours({ theme, type }).background};
  font-size: 24px;
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => theme.spacing({ top: 16, bottom: 14, left: 0, right: 0 })};
  }
`;