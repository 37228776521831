import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  ProductNutritionLabelPromoComponent,
  Block,
} from './ProductNutritionLabelPromo.styles.js';

import translate from 'helpers/translate';
import { renderLanguage } from 'helpers/languageHelper';

import PromoBox from 'components/shared/PromoBox';
import Modal from 'components/shared/Modal';
import NutritionLabelTable from 'components/NutritionLabelTable';


const ProductNutritionLabelPromo = ({ fields = {}, params = {}, sitecoreContext = {}, ...props }) => {
  const { site } = sitecoreContext;
  const isFoodserviceSite = site.name === 'bushs-beans-foodservices';

  const {
    title: fieldTitle = {},
    copy: fieldCopy = {},
    backgroundMedia = null,
  } = fields;

  const {
    alignment = 'center',
    contentPanelBackgroundColor = 'red',
    backgroundColor = null,
    contentPanelTitleColor = null,
    contentPanelCopyColor = null,
    contentPanelCtaColor = null,
  } = params;

  const {
    viewBag = {},
    salsify = {},
    language = '',
  } = sitecoreContext;

  const productName = salsify.productName || {};

  const [showNutrition, setShowNutrition] = useState(false);

  const shapeData = {
    title: {
      value: fieldTitle?.value?.length
        ? fieldTitle.value
        : `${translate(
            viewBag,
            'Products.NutritionLabelPromo.TitlePrefix'
          )} ${renderLanguage({ language, object: productName })}`,
    },
    copy: {
      value: fieldCopy?.value?.length
        ? fieldCopy.value
        : translate(viewBag, 'Products.NutritionLabelPromo.Description'),
    },
    cta: {
      text: translate(viewBag, 'Products.NutritionLabelPromo.CtaText'),
      hasAction: true,
    },
  };

  const contentBlock = {
    ...shapeData,
    backgroundColor: contentPanelBackgroundColor,
    titleColor: contentPanelTitleColor,
    copyColor: contentPanelCopyColor,
    ctaColor: contentPanelCtaColor,
    onClick: () => setShowNutrition(true),
  };

    return (
        <ProductNutritionLabelPromoComponent
            alignment={alignment}
            variant={contentPanelBackgroundColor}
            backgroundColor={backgroundColor}
            backgroundImage={backgroundMedia?.value}
        >
          <Block>
            <PromoBox {...contentBlock} variant={contentPanelBackgroundColor}/>
          </Block>

          {showNutrition && (
              <Modal callback={() => setShowNutrition(!showNutrition)}>
                <NutritionLabelTable
                    sitecoreContext={sitecoreContext}
                    translate={translate}
                />
              </Modal>
          )}
        </ProductNutritionLabelPromoComponent>
    );

};

ProductNutritionLabelPromo.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    copy: PropTypes.string,
    backgroundImage: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    cta: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    alignment: PropTypes.oneOf(['left', 'center', 'right']),
    contentPanelBackgroundColor: PropTypes.oneOf([
      'red',
      'yellow',
      'blue',
      'transparent',
    ]),
    backgroundColor: PropTypes.string,
    contentPanelTitleColor: PropTypes.string,
    contentPanelCopyColor: PropTypes.string,
    contentPanelCtaColor: PropTypes.string,
  }),
};

export default withSitecoreContext()(ProductNutritionLabelPromo);