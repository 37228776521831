import styled from 'styled-components';

export const ProductIngredientBenefitsPromoComponent = styled.section`
  padding: ${({ theme }) => `${theme.spacing(70)} ${theme.spacing(35)} ${theme.spacing(35)}`};
  background-color: ${({ theme }) => theme.colours.tan};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => `${theme.spacing(70)} ${theme.spacing(100)}`};
  }
  &.benefit-list-bushs-beans-foodservices{
    background-color: ${({ theme }) => theme.colours.brown};
    margin: 80px auto 0 auto;
    h2 {
      color: ${({ theme }) => theme.colours.tan};
      b{
        text-decoration: underline;
      }
    }
    .bean-burst{
      color: ${({ theme }) => theme.colours.tan};
    }
    ul {
      li {
        border-bottom: ${({ theme }) => `2px solid ${theme.colours.tan}`};
        .disclaimer {
          color: #fff;
        }
        h3 {
          color: ${({ theme }) => theme.colours.tan};
        }
        svg{
          color: ${({ theme }) => theme.colours.yellow} !important;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;
    align-items: center;
  }
  .bean-burst{
    color: ${({ theme }) => theme.colours.brown};
  }  
  h2 {
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(54)}, 6vw, ${theme.spacing(128)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(62)}, 6vw, ${theme.spacing(136)})`};
    text-transform: uppercase;
    span{
      display: block;
    }
    u{
      text-decoration: underline;
    }
    svg{
      display: block;
      margin: auto;
      width: 150px;
      height: 50px;
      position: relative;
      top: -15px;
      color: ${({ theme }) => theme.colours.brown};
    }
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex: 1;
      margin-right: ${({ theme }) => theme.spacing(75)};
    }
  }

  ul {
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex: 1;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: ${({ theme }) => theme.spacing(20)};
      padding-bottom: ${({ theme }) => theme.spacing(20)};
      border-bottom: ${({ theme }) => `4px solid ${theme.colours.red}`};

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      } 
      &.benefit-icon{
        &:last-of-type{
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
      &.benefit-icon {
        svg {
          min-width: 58px;
          height: auto;
          color: ${({ theme }) => theme.colours.blue};

          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            min-width: 78px;
          }
        }
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(30)};
        padding-bottom: ${({ theme }) => theme.spacing(30)};
      }

      h3 {
        margin: ${({ theme }) => `0 0 0 ${theme.spacing(20)}`};
        color: ${({ theme }) => theme.colours.red};
        font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 3vw, ${theme.spacing(40)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(29)}, 4vw, ${theme.spacing(48)})`};
        text-align: left;

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          margin: ${({ theme }) => `0 0 0 ${theme.spacing(30)}`};
        }
      }
    }
  }

  .disclaimer {
    display: block;
    margin-top: ${({ theme }) => theme.spacing(32)};
    font-size: 1rem;
    text-align: left;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-left: ${({ theme }) => theme.spacing(110)};
    }
  }
`;