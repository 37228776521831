import styled from 'styled-components';

const getVariantColours = ({variant, theme = {}, site} = {}) => {
    
    const variants = {
        red: {
            headingColor: theme.colours?.yellow,
            background: theme.colours?.red,
            title: theme.colours?.yellow,
            text: theme.colours?.white,
            button: theme.colours?.yellow,
        },
        yellow: {
            headingColor: theme.colours?.red,
            background: theme.colours?.yellow,
            title: theme.colours?.red,
            text: theme.colours?.adaBrown,
            button: theme.colours?.brown,
        },
        brown: {
            headingColor: theme.colours?.yellow,
            background: theme.colours?.brown,
            title: theme.colours?.yellow,
            text: theme.colours?.white,
            button: theme.colours?.yellow,
        },
        blue: {
            headingColor: theme.colours?.yellow,
            background: theme.colours?.blue,
            title: theme.colours?.yellow,
            text: theme.colours?.white,
            button: theme.colours?.yellowBlue,
        },
    };

    if (site && site.name === 'bushs-beans-foodservices') {
        return variants.blue;
    }

    if (variant in variants) return variants[variant];

    return variants.red;
};

export const CTAWrapper = styled.section`
	width: 1550px;
	max-width: 95%;
	position: relative;
	//background-color: ${({theme}) => theme.colours.brown};
	margin: 80px auto;
	${({theme}) => theme.breakPoints.minBp('tablet')} {
		margin: 160px auto;
	}
  &.no-top-margin{
    margin-top: ${({ theme }) => theme.spacing(0)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-top: ${({ theme }) => theme.spacing(0)};
    }
  }
  &.no-bottom-margin{
    margin-bottom: ${({ theme }) => theme.spacing(0)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(0)};
    }
  }
  &.no-top-padding{
    padding-top: ${({ theme }) => theme.spacing(0)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      padding-top: ${({ theme }) => theme.spacing(0)};
    }
  }
  &.no-bottom-padding{
    padding-bottom: ${({ theme }) => theme.spacing(0)};
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      padding-bottom: ${({ theme }) => theme.spacing(0)};
    }
  }
	@media (max-width: 1600px) {
		width: 95%;
		max-width: 95%;
	}
	@media (max-width: 980px) {
		background-color: #944000;
		padding: 0 50px;
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
        margin-bottom: 0;
	}
	@media (max-width: 680px) {
		padding: 50px 15px;
	}
`;

export const CTAContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 20px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	position: relative;
	padding: 40px 100px;
	background-color: #944000;
	box-sizing: border-box;
	@media (max-width: 980px) {
		padding: 20px 80px;
		grid-template-columns: 1fr;
	}
	@media (max-width: 680px) {
		padding: 0;
	}
`;


export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	position: relative;
	z-index: 5;
	padding: 0 50px;
`;

export const HeadingWrapper = styled.h2`
	font-size: 56px;
	font-style: normal;
	font-weight: 700;
	line-height: 60px;
	text-transform: uppercase;
	text-align: center;
	color: ${({ theme }) => theme.colours.yellow};
    margin-bottom: 15px;
	@media (max-width: 980px) {
		font-size: 32px;
		line-height: 34px;
	}
`;

export const CopyWrapper = styled.div`
	p{
        text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
		color: ${({ theme }) => theme.colours.white};
		margin-bottom: 15px;
        a{
            color: ${({ theme }) => theme.colours.white};
            font-weight: 700;
        }
		@media (max-width: 980px) {
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
		}
	}
	a{
		color: ${({ theme }) => theme.colours.white};
		font-weight: 700;
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	gap: 10px;
    justify-content: center;
`;

export const ImageContainerWrapper = styled.div`
    position: relative;
    z-index: 5;
	img {
		width: 100%;
		object-fit: contain;
    }
	@media (max-width: 980px) {
		order: 0;
	}
`;