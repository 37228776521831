import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Image, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

import {
  InRecipeProductsFoodservicesComponent,
  ProductList,
  FloatingImage,
  ProductCardComponentInRec,
} from './InRecipeProductsFoodservices.styles.js';

import Link from 'components/shared/Link';
import LoadingSpinner from 'components/shared/LoadingSpinner';

import { ConditionalComponentWrapper } from 'helpers/componentHelpers';
import translate from 'helpers/translate';
import { searchConfig, globalizationConfig } from 'helpers/searchHelpers';

import { useFetch } from 'hooks';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';
import { checkBadges, languageFilter } from 'helpers/badgeHelpers.js';
import Button from "../shared/Button";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const InRecipeProductsFoodservices = ({ sitecoreContext = {}, fields = {}, type = 'ids' }) => {
  const { viewBag = {}, trademarkReplacements = [], site } = sitecoreContext;
  const { featuredProductPages = [] } = fields;
  const { endpoint } = searchConfig({ type });

  const [state, setState] = useState({
    results: [],
    ids: [...featuredProductPages],
    globalization: globalizationConfig(),
    sortOrder: 'dateDesc',
  });

  const { results, ids, globalization } = state;

  const { country = '', language = '' } = globalization;

  const { data, loading, error } = useFetch(endpoint, {
    method: 'POST',
    body: {
      subject: {
        ids,
      },
      context: {
        globalization,
        request: {
          scheme: 'https',
          queryString: '',
          routeData: {
            site: site.name
          },
        },
        content: {},
      },
    },
  });

  useEffect(() => {
    if (data) {
      setState((oldState) => {
        return {
          ...oldState,
          results: [...data?.subject?.results],
        };
      });
    }
  }, [data]);

  if (results.length === 0) {
    return null;
  }
  const sliderSettings = {
    dots: false,
    infinite: results.length > 4,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          infinite: results.length > 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          infinite: results.length > 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
      <InRecipeProductsFoodservicesComponent>
        <h2>Products Featured</h2>
        {Array.isArray(results) && results.length > 0 ? (
            <Slider {...sliderSettings}>
              {results?.map((product, index) => {
                const {
                  searchTitle: title = '',
                  searchImage: image = {},
                  itemUrl: link = '',
                  productIsNew = false,
                  webBadge = '',
                  searchDescription: description = '',
                } = product;

                const filteredLanguage = languageFilter(language);
                const activeImgSrc = checkBadges(productIsNew, webBadge, filteredLanguage, country);

                return (
                    <div key={`recipe-product-promo-${index}`} className="slide">
                      <ProductCardComponentInRec type={type} className={`product-card bg-yellow`}>
                        <ConditionalComponentWrapper
                            condition={link}
                            wrapper={(children) => (
                                <Link href={link} class={null}>
                                  {activeImgSrc && (
                                      <img className="recipe-card-badge" src={activeImgSrc} alt={'New Look'}/>
                                  )}
                                  {children}
                                </Link>
                            )}
                        >
                          <div className="image-container">
                            {image?.url && <img src={image?.url} alt={image?.alt}/>}
                          </div>
                          <footer>
                            {title && (
                                <h3
                                    dangerouslySetInnerHTML={{
                                      __html: trademarkFilterHelper(title, trademarkReplacements),
                                    }}
                                />
                            )}
                            {description && (
                                <p
                                    className="desc"
                                    dangerouslySetInnerHTML={{
                                      __html: trademarkFilterHelper(description, trademarkReplacements),
                                    }}
                                />
                            )}
                          </footer>
                        </ConditionalComponentWrapper>
                      </ProductCardComponentInRec>
                    </div>
                );
              })}
            </Slider>
        ) : (
            <LoadingSpinner/>
        )}
        <div className="button-container">
          <Button id="related-pages-btn" target="_blank" text="View All Products" href="/products"
                  variant="yellowBrown"/>
        </div>
      </InRecipeProductsFoodservicesComponent>
  );
};

InRecipeProductsFoodservices.propTypes = {};

export default withSitecoreContext()(InRecipeProductsFoodservices);