import styled from "styled-components";

export const RecipeOverviewWrapper = styled.section`
    position: relative;
    background-color: ${({ theme }) => theme.colours.tan};
	margin: 80px 0;
    padding: 0 20px;
	${({ theme }) => theme.breakPoints.minBp('tablet')} {
		margin: 160px 0;
	}
`;

export const RecipeOverviewInner = styled.div`
    position: relative;
    .container{
		display: grid;
		grid-template-columns: calc(40% - 40px) calc(60% - 40px);
		gap: 80px;
		width: calc(1300px + 80px);
		margin: 0 auto;
		max-width: 100%;
		@media (max-width: 1000px) {
			grid-template-columns: 1fr;
		}
        .recipe-overview{
            .recipe-detail-image{
                width: 100%;
                max-width: 100%;
                height: auto;
                margin-bottom: 20px;
				@media print {
					display: none !important;

					@page {
						margin: 0;
					}

					body {
						margin: 1.6cm;
					}
				}
            }
            h2{
				font-family: 'apercu condensed pro', apercu condensed pro, 'apercu pro', impact;
				font-size: 56px;
				font-style: normal;
				font-weight: 700;
				text-transform: uppercase;
                text-align: left;
				margin-top: 40px;
				margin-bottom: 15px;
				line-height: 1;
            }
            p{
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 26px;
            }
        }
    }
    .download-link{
        color: ${({ theme }) => theme.colours.brown};
        display: flex;
        font-size: 20px;
		line-height: 26px;
		margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        .link-text{
			font-family: 'apercu condensed pro', apercu condensed pro, 'apercu pro', impact;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 26px;
			text-transform: uppercase;
            margin-right: 10px;
            border-bottom: 2px solid ${({ theme }) => theme.colours.brown};
        }
        .circle{
			display: flex;
            justify-content: center;
			border-radius: 50%;
			height: 28px;
			width: 28px;
			position: relative;
			top: 2px;
			margin: 0;
			text-align: center;
			background-color: ${({ theme }) => theme.colours.brown};
            svg{
                fill: ${({ theme }) => theme.colours.tan};
				width: 15px;
				align-self: center;
				display: flex;
				justify-self: center;
				position: relative;
				top: -2px;
				transition: all 0.2s ease-in-out;
            }
        }
        &:hover{
            .circle{
                svg{
                    top: 0px;
                }
            }
        }
		@media print {
			display: none !important;

			@page {
				margin: 0;
			}

			body {
				margin: 1.6cm;
			}
		}
    }
    #instruction-list{
        list-style-type: decimal;
        margin-left: 20px;
		position: relative;
		left: 20px;
        max-width: 90%;
        li{
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 15px;
        }
    }
    .product-ingredients-desktop{
        @media (max-width: 1000px) {
            display: none;
        }
    }
	.product-ingredients-mobile{
        display: none;
        margin-top: 20px;
		@media (max-width: 1000px) {
			display: block;
		}
	}
`;

export const RecipeDetailbox = styled.div`
    position: relative;
    border: 2px solid ${({ theme }) => theme.colours.blue};
    background-color: #fff;
    margin-bottom: 20px;
    h3{
        display: block;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px; /* 106.25% */
		text-transform: uppercase;
        padding: 10px 40px;
        color: ${({ theme }) => theme.colours.yellow};
        background-color: ${({ theme }) => theme.colours.blue};
        text-align: left;
        margin: 0;
    }
    
`;


export const DetailBoxList = styled.div`
    position: relative;
    padding: 40px;
    display: grid;
    .ingredients-item{
        margin-bottom: 20px;
        h4{
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 10px;
            text-align: left;
        }
        ul{
            list-style-type: none;
            margin-left: 20px;
            li{
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 10px;
            }
        
        }
    }
    @media (max-width: 700px) {
        .info-cols{
            grid-template-columns: 1fr;
        }
    }
`;


export const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(40)};
    }
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    order: 2;
  }
	.socialShare{
        padding: 0;
		background: none;
		h3{
			text-align: left;
			margin: 0 0 20px 0;
		}
		.social-links{
			justify-content: flex-start;
            li{
                padding: 0;
            }
		}
        @media (max-width: 1199px){
            display: none;
        }
	}  
`;

export const Ingredients = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(40)};
  border: 2px solid ${({ theme }) => theme.colours.red};

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(20)};
    background-color: ${({ theme }) => theme.colours.red};

    h2 {
      margin: 0;
      color: ${({ theme }) => theme.colours.yellow};
      font-weight: 400;
      text-align: left;
      text-transform: uppercase;
    }

    span {
      padding: ${({ theme }) => theme.spacing(10)};
      color: ${({ theme }) => theme.colours.tan};
      border: 2px solid ${({ theme }) => theme.colours.yellow};
      border-radius: 100px;
      font-size: 20px;
      font-weight: 900;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        padding: 0.8rem;
        font-size: 20px;
        font-weight: 900;
      }
    }
  }

  > div {
    padding: ${({ theme }) => theme.spacing({ top: 40, bottom: 40, left: 20, right: 20 })};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(40)};
    }
  }

  ul {
    margin-bottom: ${({ theme }) => theme.spacing(32)};
    list-style: disc;

    h4 {
      text-align: left;
      @media print {
        font-size: 12px !important;
        font-weight: normal !important;
        margin-bottom: 5px !important;
      }
    }

    li {
      margin: ${({ theme }) => theme.spacing({ left: 16, bottom: 16, top: 0, right: 0 })};

      sup {
        top: -3px;
      }
    }
  }

  button {
    margin-top: ${({ theme }) => theme.spacing(30)};
    color: ${({ theme }) => theme.colours.red};
    text-decoration: underline;
  }
`;

export const Nutrition = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(46)};
  text-align: center;
  border-bottom: ${({ isNutritionalInfoEmpty, theme }) =>
    !isNutritionalInfoEmpty ? `2px solid ${theme.colours.red};` : `none`};
  @media print {
    border-bottom: none !important;
  }
  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    text-align: left;
    border-bottom: 0;
  }

  p {
    margin-bottom: ${({ theme }) => theme.spacing(30)};
  }

  button {
    span {
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    }
  }

  @media print {
    float: left !important;
    color: black !important;
    padding: 0 !important;
    h2 {
      font-size: 18px !important;
      font-weight: normal !important;
      line-height: 0 !important;
      text-align: left !important;
      margin: 10px 0px 10px 0px !important;
    }
    p {
      text-align: left !important;
      font-size: 12px !important;
      line-height: 1rem !important;
    }
  }
`;

export const ListRow = styled.li`
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	margin-bottom: 20px;
	.step{
		display: block;
		.instructions-header-on-print{
			display: inline-block;
			font-weight: 900;
		}
		.description{
			display: inline-block;
            .instruction-label{
                display: inline-block;
                margin-right: 10px;
                font-weight: 900;
                color: ${({ theme }) => theme.colours.blue};
            }
            p{
                margin-bottom: 10px;
                display: inline;
            }
		}
	}
	ul.ingredients{
		display: flex;
		li{
            margin-right: 10px;
			display: flex;
            svg{
                color: ${({ theme }) => theme.colours.blue};
            }
            p{
                margin-bottom: 0;
            }
		}
	}
`;

export const NutritionWindow = styled.div`
    position: relative;
    display: block;
    min-height: 300px;
    background-color: #fff;
	width: auto;
	padding: 0;
    z-index: 5;
	max-height: 90vh;
	overflow-y: scroll;
    h3{
        color: ${({ theme }) => theme.colours.yellow};
        font-size: 20px;
        line-height: 34px;
        background-color: ${({ theme }) => theme.colours.blue};
        text-align: left;
        display: block;
        padding: 10px 20px;
        text-transform: uppercase;
        margin-bottom: 0;
	}
    h4{
		color: ${({ theme }) => theme.colours.yellow};
		font-size: 18px;
		line-height: 1;
        font-weight: 700;
		background-color: ${({ theme }) => theme.colours.blue};
		text-align: right;
		display: block;
		padding: 10px 20px;
		margin-bottom: 0;
    }
    .servings{
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border-bottom: 1px solid ${({ theme }) => theme.colours.blue};
        color: ${({ theme }) => theme.colours.brown};
    }
    ul{
        padding: 20px 0;
        li{
            display: block;
            justify-content: space-between;
			border-bottom: 1px solid ${({ theme }) => theme.colours.blue};
			padding: 10px 15px;
            color: ${({ theme }) => theme.colours.brown};
			text-transform: capitalize;
            &:last-of-type{
                border-bottom: none;
				padding: 10px 15px;
            }
            .val{
                margin-left: 20px;
            }
            .percent{
                position: absolute;
                right: 20px;
            }
        }
    }
    .info-message p{
		padding: 10px 15px 0 15px;
        color: ${({ theme }) => theme.colours.brown};
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-style: italic !important;
        font-weight: 200;
        margin-bottom: 20px;
    } 
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
	border-radius: 10px;
	width: 500px;
	max-width: 80%;
	position: relative;
    padding: 20px;
	box-shadow: 4px 20px 50px #000;
	margin: auto;
    background-color: #f0e8d7;
    .closeButton{
        position: absolute;
		right: -15px;
		top: -15px;
		padding: 10px 15px;
        color: ${({ theme }) => theme.colours.blue};
        font-weight: 700;
        font-size: 25px;
        cursor: pointer;
        z-index: 10;
        background-color: ${({ theme }) => theme.colours.yellow};
        border-radius: 50%;
        border: 1px solid #fff;
        transition: all 0.3s ease-in-out;
        span{
            display: none;
        }
        &:hover{
            background-color: ${({ theme }) => theme.colours.blue};
            color: ${({ theme }) => theme.colours.yellow};
        }
    }
	.socialShare{
		padding: 0;
		background: none;
        max-width: 92%;
		h3{
			text-align: center;
			margin: 0 auto 30px auto;
		}
		.social-links{
			justify-content: center;
            gap: 15px;
			li{
				padding: 0;
				.button-circle{
                    background-color: ${({ theme }) => theme.colours.blue};
                    color: ${({ theme }) => theme.colours.yellow};
                    &:hover{
						background-color: ${({ theme }) => theme.colours.yellow};
                        svg{
                            fill: white;
                        }
                    }
                }
                .button-link{
                    &:hover{
						svg{
							fill: white;
						}
                    }
                }
			}
		}
	}
`;

export const PlaceholderWrapper = styled.div`
    position: relative;
    display: block;
    min-height: 300px;
`;

export const Placeholder = styled.div`
    position: relative;
    display: block;
`;